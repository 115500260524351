import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import unified from 'unified'
import markdown from 'remark-parse'
import rehype from 'remark-rehype'
import raw from 'rehype-raw'
import stringify from 'rehype-stringify'

import Layout from '../components/Layout/Layout'

import styles from '../assets/styles/blog-post.module.scss'

export default function Template({
  data, // this prop will be injected by the GraphQL query we'll write in a bit
}) {
  const { airtable: post } = data // data.markdownRemark holds your post data

  // unified processor for parsing MD and HTML
  const processor = unified()
    .use(markdown)
    .use(rehype, { allowDangerousHtml: true })
    .use(raw)
    .use(stringify)

  const blogPostImage = filename => {
    const localFiles = post.data.Additional_Images.localFiles
    localFiles.find(img => console.log(img.name + img.extension))
    const file = localFiles.find(
      img =>
        img.name.split('.')[0].replace(/[ \\()]/g, '') ===
        filename.split('.')[0].replace(/[ \\()]/g, '')
    )

    if (file) {
      const url = file.publicURL

      return ReactDOMServer.renderToString(
        <img src={url} alt={`${filename}`} style={{ width: '100%' }} />
      )
    } else {
      console.log(localFiles)
      console.log(filename)
      console.log(file)
      throw new Error('Requested blog image file not found')
    }
  }

  let blogTextWithImages = ''

  if (
    post.data.Additional_Images &&
    post.data.Additional_Images.localFiles.length > 0
  ) {
    const textSections = post.data.Blog_Text.split(/\[IMAGE: ["“].*["”]\]/g)
    const imageFileNames = [
      ...post.data.Blog_Text.matchAll(/\[IMAGE: ["“](.*)["”]\]/g),
    ]

    textSections.forEach((text, index) => {
      if (post.data.Additional_Images.localFiles[index]) {
        blogTextWithImages =
          blogTextWithImages + text + blogPostImage(imageFileNames[index][1])
      } else {
        blogTextWithImages += text
      }
    })
  } else {
    blogTextWithImages = post.data.Blog_Text
  }

  return (
    <Layout>
      <article className={styles.main}>
        <Helmet
          title={`HIV Policy Lab: ${post.data.Title}`}
          meta={[
            {
              name: 'description',
              content: `HIV Policy Lab: ${post.data.Title}`,
            },
          ]}
        />
        <Link to="/activities/">&lt; back to all activities</Link>
        <header>
          <h1>{post.data.Title}</h1>
          <h2>
            {new Date(post.data.Date).toLocaleString('default', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              timeZone: 'UTC',
            })}
          </h2>
        </header>
        {
          /* Hide author if there is none */
          post.data.Author !== '' && (
            <h3
              dangerouslySetInnerHTML={{
                __html: post.data.Author,
              }}
            />
          )
        }

        {/* <div className={styles.imageContainer}> */}
        {/*   <img src={post.data.Cover_Image[0].url} alt={post.data.Title} /> */}
        {/* </div> */}

        <section
          dangerouslySetInnerHTML={{
            __html: processor.processSync(blogTextWithImages),
          }}
        />

        <footer className={styles.tags}>
          <h3>Tags:</h3>
          {post.data.Tags.map(tag => (
            <Link
              key={tag}
              to="/activities"
              state={{ tags: [tag] }}
              className={styles.tag}
            >
              {tag}
            </Link>
          ))}
        </footer>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    airtable(data: { URL: { eq: $path } }) {
      id
      data {
        Blog_Text
        Date
        Cover_Image {
          localFiles {
            publicURL
          }
        }
        Additional_Images {
          localFiles {
            name
            extension
            publicURL
          }
        }
        URL
        Title
        Author
        Tags
      }
    }
  }
`
